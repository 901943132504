/*Base Styles*/

html {
  height: 100%;
}

body {
  font: {
    family: $font-family-base;
    display: swap;
    size: $font-size-base;
    weight: 400;
  }
  height: 100%;
}

#app-site,
.app-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 0;
  min-height: 100vh;
}

.page-wrapper {
  background-color: $white;
  padding: $generic-padding;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-start {
  align-items: flex-start;
}

.d-block {
  display: block !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-none {
  display: none;
}

.flex-auto {
  flex: auto;
}

.page-content {
  padding: $generic-padding;
  position: relative;
}

.success {
  color: $success;
}

.danger {
  color: $danger;
}

.bg-success {
  background-color: $success-bg;
}

.bg-color-primary {
  background-color: $primary !important;
}

.bg-color-secondary {
  background-color: $secondary !important;
}

.bg-color-white {
  background-color: $white !important;
}

.bg-color-transparent {
  background-color: $transparent-color;
}

.bg-none {
  background: none;
}

.bg-content-secondary-head-shape::before {
  content: url('../../../public/assets/images/secondary-head-shape.svg');
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}

.app-logo img {
  height: 27px;
  width: 81.331px;
}

.text-center {
  text-align: center;
}

.text-link {
  color: $link-color;
}

.text-left {
  text-align: left !important;
}

.text-bold {
  font-weight: bold;
}

.text-normal {
  font-weight: normal;
}

.text-italic {
  font-style: italic;
}

.text-line-through {
  text-decoration: line-through;
}

.text-primary {
  color: $primary;
}

.text-primary-light {
  color: $primary-light;
}

.text-secondary {
  color: $secondary;
}

.text-black {
  color: $black;
}

.text-white {
  color: $white;
}

@media (min-width: $md-device-width-min) {
  .d-after-md-none {
    display: none !important;
  }
}

@media (max-width: $md-device-width-max) {
  .d-under-md-none {
    display: none !important;
  }
  .main-app-container {
    padding: 0;
  }
  .page-title-container {
    padding: $generic-padding $generic-padding 0;
  }
  .pl-generic-under-md {
    padding-left: $generic-padding;
  }
}

.flex-unset {
  flex: unset;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.center-content {
  @include flex-center();
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.my-auto {
  @extend .mt-auto;
  @extend .mb-auto;
}

.mx-auto {
  @extend .ml-auto;
  @extend .mr-auto;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.request-card-wrapper {
  height: 280px !important;
}

.m-auto {
  margin: auto !important;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.underline {
  text-decoration: underline !important;
}

.on-hover-underline:hover {
  text-decoration: underline !important;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.cursor-copying {
  cursor: copy;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.text-gray {
  color: $text-color-gray;
}

.ant-drawer-content-wrapper {
  width: $side-nav-width !important;
}

button.button-style-none {
  background-color: transparent;
  border: none;
}

button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.fade-in {
  animation: fadeIn ease 0.8s;
  -webkit-animation: fadeIn ease 0.4s;
  -moz-animation: fadeIn ease 0.4s;
  -o-animation: fadeIn ease 0.4s;
  -ms-animation: fadeIn ease 0.4s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out-left {
  animation: fadeOutLeft ease 0.4s;
  -webkit-animation: fadeOutLeft ease 0.4s;
  -moz-animation: fadeOutLeft ease 0.4s;
  -o-animation: fadeOutLeft ease 0.4s;
  -ms-animation: fadeOutLeft ease 0.4s;
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-30px);
  }
}

.background-loading {
  animation-duration: 5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeHolderShimmer;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%) !important;
  background-size: 800px 104px;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.has-background-loading .ant-select-selection {
  @extend .background-loading;
}

.icon-circle {
  border: 1px solid $text-color;
  border-radius: 50%;
  padding: 5px;
  height: 30px;
  width: 30px;
  margin-right: 10px;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  line-height: 17px;
}

.font-weight-normal {
  font-weight: normal;
}

.padding-left {
  padding-left: $generic-padding !important;
}

li {
  list-style: none;
}

.mb-generic {
  margin-bottom: $generic-padding;
}

.text-underline {
  text-decoration: underline;
}

.flex-direction-col {
  flex-direction: column;
}

.text-xxl {
  font-size: $font-size-xxl;
}

.text-xl {
  font-size: $font-size-xl;
}

.text-lg {
  font-size: $font-size-lg;
}

.text-base {
  font-size: $font-size-base;
}

.text-sm {
  font-size: $font-size-sm;
}

.text-xs {
  font-size: $font-size-xs;
}

@media (max-width: $md-device-width-max) {
  .p-md-0 {
    padding: 0 !important;
  }
  .d-md-none {
    display: none !important;
  }
}

.color-secondary {
  color: $secondary;
}

.border-secondary {
  border-color: $secondary;
}

.border-success {
  border: solid $success 1px;
}

.primary-light-border {
  border: solid $primary-light 1px;
}

.border-default {
  border: 1px solid $border-color;
}

.shadow-sm {
  box-shadow: $shadow-sm;
}

.shadow-md {
  box-shadow: $shadow;
}

.shadow-lg {
  box-shadow: $shadow-lg;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  -webkit-appearance: none;
  border: none;
}

.text-ellipsis {
  max-width: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

@media (max-width: $mobile-device-width-max) {
  .page-content {
    padding: $generic-padding-mobile;
  }
}

.success {
  color: $success;
}

.ant-form-extra {
  color: #00000073 !important;
}

.ant-select-selection-item {
  .option-info-icon {
    display: none;
  }
}

.user-selection-none {
  user-select: none;
}

.position-relative {
  position: relative;
}

.plus-icon {
  background-color: $secondary;
  color: $white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 20px;
}

.white-space-normal {
  white-space: normal;
}

.no-wrap {
  white-space: nowrap;
}

.text-right {
  text-align: right !important;
}

.spinner-container {
  min-height: 100px;
  @extend .center-content;
}

.ant-spin-dot-item {
  background-color: $secondary;
}

.fill-white {
  fill: white;
}

.border-none,
.border-top-none {
  border-top: none;
}

.border-none,
.border-bottom-none {
  border-bottom: none;
}

.border-none,
.border-right-none {
  border-right: none;
}

.border-none,
.border-left-none {
  border-left: none;
}

.kicon-box {
  display: flex;

  span {
    margin: auto !important;
  }
}

.kicon-box-md {
  padding: 2px;
  height: 24px;
  line-height: 24px;
  width: 24px;
  font-size: $icon-md-font-size;
}

.kicon-box-sm {
  padding: 1px;
  height: 16px;
  width: 16px;
  line-height: 16px;
  font-size: $icon-sm-font-size;
}

.half-opacity {
  opacity: 0.5 !important;
}

.overflow-hidden {
  overflow: hidden;
}

.hidden {
  visibility: hidden;
}
