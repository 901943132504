.ant-table {
  .ant-table-thead {
    tr {
      th {
        background-color: #e8e8e8;
        border-bottom: 1px solid #d8d8d8;
      }
    }
  }

  .ant-table-cell {
    border-bottom: 1px solid #d8d8d8;
  }
  @media screen and (max-width: 767px) {
    overflow-x: auto;
    table {
      min-width: 767px;
    }
    .ant-table-cell-fix-right {
      border-left: 1px solid $border-color;
      background-color: $white !important;
    }
  }

  tr .hidden {
    @include transition(all 0.4s ease);
    opacity: 0;
  }

  tr.display-column-on-hover:hover {
    .hidden {
      opacity: 1;
      visibility: visible;
    }
  }
}
