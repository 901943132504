.select-with-search {
  width: 100%;
}

.ant-form-item-label label::after {
  display: none;
}

.has-white-feedback .ant-form-item-explain {
  color: $white !important;
}

.custom-file-upload {
  background-color: $background-secondary-light !important;
  border-color: $text-color-gray;
}

fieldset:disabled {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;

  input,
  .ant-radio-wrapper,
  .ant-radio-button-wrapper {
    cursor: not-allowed;
  }
}
