.finalize-rental-request-container {
  .finalize-rental-request-item-title {
    font-size: 22px;
    color: $black;
  }

  .finalize-request-header-icon {
    position: absolute;
    left: -30px;

    img {
      width: 30px;
    }
  }

  .finalize-request-header-icon--sub-item {
    position: absolute;
    left: -30px;
    top: 0;

    img {
      width: 20px;
    }
  }

  .ant-collapse-item {
    border: none;
  }

  .ant-collapse-content-box,
  .ant-collapse-header {
    padding: 0 !important;
  }
}
