@mixin button-color($color: $white, $bg-color: $primary, $hover: $primary-light) {
  color: $color;
  background-color: $bg-color;
  border-color: $bg-color;

  &:hover,
  &:focus,
  &:active {
    background-color: $hover;
    border-color: $hover;
  }
}

.ant-btn.ant-btn-sm {
  padding: 0 7px;
}

.ant-btn {
  max-width: 100%;

  &.ant-btn-secondary {
    @include button-color($white-shade, $secondary, $secondary-light);
  }

  &.ant-btn-default {
    @include button-color();
  }

  &.ant-btn-danger {
    @include button-color($white, $danger, $secondary-light);
  }

  &.ant-btn-secondary-border {
    color: $text-color;
    border-color: $primary;
    background-color: $white;

    &:hover,
    &:focus,
    &:active {
      color: $primary-light;
      background-color: $white;
      border-color: $primary-light;
    }
  }

  &.btn-transparent {
    @include button-color($primary, $transparent-color, $transparent-color);
  }
}
