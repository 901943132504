.ant-collapse-borderless {
  background: $white;
}

.ant-table-column-sorters {
  padding: 0;
}

.kicon-box {
  .anticon {
    vertical-align: 0 !important;
  }
}

*:focus {
  outline: none;
}

.ant-legacy-form-item-label {
  label {
    color: $headings-color !important;
  }
}
