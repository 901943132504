.roles-container {
  .role-info-container {
    margin-top: 20px;
    &:first-child {
      margin: 0;
    }
    .custom-grid {
      box-shadow: unset;
    }
    .ant-card-head {
      border: none;
    }
    table {
      padding: 0 20px;
      td,
      th {
        background: transparent;
        padding: 5px;
        max-width: 60px;
        &:first-child {
          text-align: left;
        }
        &:last-child {
          text-align: right;
        }
      }
      th {
        font-weight: bold;
        color: $text-color-gray;
        border: none;
        text-align: center;
      }
    }
  }
  .role-card-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
