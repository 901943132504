.ant-message-notice {
  text-align: right !important;
  top: 40px;

  .ant-message-notice-content {
    padding: 0;

    & > div {
      padding: 10px 16px;

      &,
      .anticon {
        color: $white;
      }
    }

    .ant-message-error {
      background-color: $secondary;
    }

    .ant-message-success {
      background-color: $success;
    }

    .ant-message-warning {
      background-color: $warning;
    }

    .ant-message-info {
      background-color: $info;
    }
  }
}

.ant-message {
  z-index: $ant-message-z-index;
  top: 80px;
}
