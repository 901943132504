@media (max-width: $md-device-width-max) {
  .app-header {
    padding: 0 $generic-padding 0 0;
    .menu-button {
      margin-right: 10px;
      -webkit-appearance: none;
      border: none;
    }
  }
}

.app-header {
  position: sticky;
  top: 0;
  .profile-button {
    border: 1px solid $white;
    background-color: $secondary;
    color: $white;
    &:hover {
      background-color: $white;
      color: $secondary;
      border-color: $secondary;
    }
  }
}

.custom-header {
  background: $primary !important;
  @include box-shadow(
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12)
  );
  left: 0;
  right: 0;
  z-index: $header-z-index;
}
