.ant-alert {
  &.ant-alert-default {
    border: none;
    background-color: #f1f2f5;

    .ant-alert-message {
      color: $black;
    }
  }
}
