.k-timer {
  font-weight: bold;
  color: $headings-color;
  font-size: 50px;
  display: flex;

  .minutes,
  .seconds,
  .separator {
    @extend .mx-1, .my-auto;
  }

  .minutes,
  .seconds {
    @extend .p-2;
    background-color: #fff0f0;
    border-radius: $border-radius-lg;
    width: 82px;
    text-align: center;

    span {
      line-height: 50px;
    }
  }
}
