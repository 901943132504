.ant-layout-sider-trigger {
  background-color: #fff2f0;
  color: $secondary;
}

.ant-layout-sider {
  background: $transparent-color;
}

.ant-layout-sider-children {
  background-color: $white;
  position: sticky;
  top: $app-header-height;
  height: calc(100vh - 110px);
}

.ant-tooltip-content .ant-badge sup {
  display: none;
}

.ant-layout-sider .ant-badge sup p {
  color: white;
}

.ant-menu:not(.ant-menu-horizontal) .menu-item-with-badge {
  height: 45px;
}
.menu-item-with-badge .ant-badge,
.ant-tooltip-content .ant-badge {
  color: unset;
}

.manager-switch-wrapper.ant-menu-item {
  margin-top: 20px;
  .anticon {
    display: none;
    & + span {
      margin-left: 0;
    }
  }
  button {
    @include flex-center();
    width: 100%;
  }
}

.ant-menu-inline-collapsed > .manager-switch-wrapper.ant-menu-item {
  padding: 0 10px !important;
  .anticon {
    display: flex;
    line-height: 1;
  }
}
