.flag,
.flag.flag-24 {
  display: inline-block;
  background-repeat: no-repeat;
}
.flag.flag-24 {
  width: 24px;
  height: 24px;
  background-image: url(images_url('sprite-flags-24x24.png'));
}
.flag.flag-24.flag-de {
  background-position: -144px -96px;
}
.flag.flag-24.flag-us {
  background-position: -168px -336px;
}
.flag.flag-24.flag-england {
  background-position: -96px 0;
}
.flag.flag-24.flag-tr {
  background-position: 0 -336px;
}
