.core-category {
  .ant-list-header {
    box-shadow:
      0 0 0 0 rgba(0, 0, 0, 0.2),
      0 2px 5px 0 rgba(0, 0, 0, 0.14),
      0 0 0 0 rgba(0, 0, 0, 0.12);
  }

  .ant-tree-node-content-wrapper {
    height: inherit !important;
    width: 100%;
    @extend .my-0, .py-0;
  }

  .ant-tree-child-tree {
    li {
      @extend .py-0;
    }
  }

  .ant-tree {
    li {
      @extend .py-0;
    }

    ul {
      @extend .my-0;
      margin-left: 94px;
    }
  }

  .ant-checkbox-wrapper {
    display: flex;
    @extend .full-width;
  }

  .ant-checkbox {
    @extend .my-auto;
  }
}
