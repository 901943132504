.dashboard-tabs {
  .ant-tabs-nav {
    padding: 0 16px 0 20px;
    background-color: $white;
    margin-bottom: 0;
  }

  .ant-tabs-top-bar {
    margin-bottom: 0;
  }
}

.custom-card-wrapper {
  height: 100%;
  .right-corner-icon {
    position: absolute;
    top: 21px;
    right: 41px;
  }
}

.dashboard-tabs {
  .custom-card-wrapper {
    .ant-card-head-title {
      white-space: normal;
      max-width: 80%;
    }
  }
}

.stations-dashboard-card-title {
  max-width: 70%;
  min-height: 4rem;
}
