@mixin radio-button-color($color, $bg-color) {
  .ant-radio-wrapper-checked {
    background-color: $bg-color;
    color: $color;
    border: 1px solid $color;

    .ant-radio-checked .ant-radio-inner {
      border-color: $color !important;

      &::after {
        background-color: $color;
      }
    }
  }
}

.button-group {
  .button-success {
    @include radio-button-color($success, $success-bg);
  }

  .button-danger {
    @include radio-button-color($danger, $danger-bg);
  }

  .ant-radio-group {
    .ant-radio-wrapper {
      @include box-shadow(0px 1px 4px 0px rgba(0, 0, 0, 0.5));
      @include border-radius(4px);
      margin-bottom: 5px;
      padding: 5px;
    }

    .ant-radio-wrapper {
      color: $primary-light;

      .ant-radio {
        .ant-radio-inner {
          border-color: $text-color-gray;
          margin: 2px 0 2px 5px;
        }

        &:hover {
          .ant-radio-inner {
            border-color: $primary;
          }
        }
      }
    }
  }
}

.vertical-radio-button-group {
  .ant-radio-wrapper {
    display: block;
    height: 30px;
    line-height: 30px;
  }
}

.vertical-checkbox-group {
  display: flex;
  flex-direction: column;
  .ant-checkbox-group-item {
    height: 30px;
    line-height: 30px;
  }
}
