.ant-rate {
  &.ant-rate-primary {
    color: $primary-light !important;
  }

  &.ant-rate-reviews {
    justify-content: space-around;
    display: flex;
    font-size: 30px;
  }

  &.ant-rate-pointer {
    .ant-rate-star {
      @extend .pointer;
    }
  }
}
