.categories-step {
  .selected-category-item {
    background-color: rgba(54, 112, 112, 0.05);
  }
}

.category-segment-step {
  .ant-tabs-nav {
    border-bottom: 0 !important;
  }

  .ant-tabs-tab {
    .category-tab-item {
      border-radius: 12px;
    }
  }

  .ant-tabs-tab::after {
    height: 0;
    width: 32px;
    top: 50%;
    right: -32px;
    position: absolute;
    content: '';
    @extend .primary-light-border;
  }

  .ant-tabs-tab-active > div {
    background-color: $success-bg !important;
  }

  .ant-tabs-ink-bar {
    display: none !important;
  }

  .ant-tabs-tab-prev {
    @extend .shadow-md;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    left: 5px;
    top: 19%;
    background-color: $white;
  }

  .ant-tabs-tab-next {
    @extend .shadow-md;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    right: 5px;
    top: 19%;
    background-color: $white;
  }

  .ant-tabs-nav-container-scroll {
    padding-right: 0;
    padding-left: 0;
  }

  .ant-tabs-tab:last-child::after {
    display: none;
  }
}

.fleet-review-option {
  @extend .text-center, .border-default;
  padding: $generic-padding;

  .icon-container {
    @extend .d-flex;
    height: 120px;
  }

  .title {
    @extend .text-bold, .text-black, .m-auto;
    width: 80%;
  }
}
