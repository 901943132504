$spacing-between-lists: 30px !default;

.availability-request-container {
  h2 {
    line-height: 1;
  }

  .request-input-wrapper {
    max-width: 90%;
  }

  .ant-collapse-borderless > .ant-collapse-item {
    border: none;
  }

  .cargo-list-wrapper {
    ul {
      padding-left: 20px;
    }

    li {
      border: none;
      padding: 0;
      color: $primary-light;
      list-style: disc;
    }
  }

  .ant-collapse-header {
    cursor: default !important;
  }

  .rental-request-item-title {
    font-size: 22px;
    color: $black;
  }

  .request-header-icon {
    position: absolute;
    left: -30px;
    top: 0;

    img {
      width: 30px;
    }
  }
}

@media (min-width: $md-device-width-min) {
  .rental-request-sticky-card {
    position: sticky;
    top: 0;
  }
}

.attribute-values-panel {
  background-color: #f0f2f5;
  border-radius: 4px;
  @extend .p-4;
}
