.custom-card-wrapper {
  & .ant-card-head {
    border: none;
  }

  .info-icon {
    position: absolute;
    right: 12px;
    top: 12px;
    color: $border-color;
  }
}

.has-title {
  .ant-card-body {
    padding-top: 0;
  }
}

.station-dashboard-card-title {
  max-width: 70%;
}
