#app-site {
  .react-calendar-timeline {
    width: 100%;

    .rct-header-root {
      background-color: #e8e8e8;
      border-bottom: 1px solid #d8d8d8;

      div {
        display: flex;
      }
    }

    .rct-calendar-header {
      border: none;
      display: flex;
      height: 40px;

      div {
        height: 40px;
      }
    }

    .rct-vertical-lines .rct-vl {
      border-left: 1px solid #d8d8d8;

      &.rct-day-6,
      &.rct-day-0 {
        background-color: #e8e8e8;
        opacity: 0.6;
      }
    }

    .rct-horizontal-lines {
      .rct-hl-odd,
      .rct-hl-even {
        background-color: #fff;
        border-bottom: 1px solid #d8d8d85c;
      }

      :last-child {
        border-bottom: none !important;
      }
    }

    .rct-sidebar-row {
      background-color: transparent;
      border-bottom: 1px solid #d8d8d85c;
    }

    .rct-dateHeader {
      border: none;
      background-color: #e8e8e8;
      pointer-events: none !important;
      color: $primary;
      font-size: 12;
    }

    .rct-sidebar {
      border-right: 1px solid #d8d8d8;
    }

    .rct-sidebar-row:last-child {
      border-bottom: none !important;
    }
  }

  .rental-park-timeline-container {
    .rct-sidebar-row {
      & > div {
        display: flex;
        justify-content: center;
        padding: 0 8px;
        flex-direction: column;
        height: 100%;
      }

      p {
        margin-bottom: 0;
        font-weight: normal;
        font-size: 14;
        line-height: 1;
        text-align: center;
        @include text-truncate();
      }
    }
  }
}
