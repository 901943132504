.ant-drawer {
  z-index: $sidebar-z-index;
}

.ant-drawer-header {
  padding: 20px;
}

.extra-info-drawer-container {
  .ant-drawer-content-wrapper {
    width: $extra-info-side-nav-width !important;
  }
}

@media (max-width: $mobile-device-width-max) {
  .extra-info-drawer-container {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
}

.profile-sidebar-drawer-container {
  .ant-drawer-content-wrapper {
    width: $profile-sidebar-width !important;
  }
}

@media (max-width: $mobile-device-width-max) {
  .profile-sidebar-drawer-container {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
}
