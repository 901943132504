.sidebar-submit-button-wrapper {
  button {
    width: 100%;
    height: 50px;
    margin-top: 20px;
  }
}

.ant-select-selection__choice {
  max-width: 120px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
